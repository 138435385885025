import React from 'react'
import './insideheader.css'

const InsideHeaderMap = ({ pageTitle, pageSubTitle, pageBackground }) => (

    <header className="header text-white contactheader" style={{paddingTop: "80px", paddingBottom: "0px", backgroundColor: '#fff'}} >
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7009.758429885697!2d77.229459!3d28.54335!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce22ff0dda9dd%3A0xe799692f7fcd3929!2s91%2C+Block+C%2C+Panchsheel+Enclave%2C+New+Delhi%2C+Delhi+110017!5e0!3m2!1sen!2sin!4v1536848824682" width="100%" height="400" frameBorder="0" style={{border: '0'}} allowFullscreen></iframe>
    </header>


)

export default InsideHeaderMap
