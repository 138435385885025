import React from 'react'
import './insideheader.css'

import aboutHeader from '../assets/images/preview/about.jpg'

const InsideHeader = ({ pageTitle, pageSubTitle, pageBackground }) => (

  <header className="header text-white" style={{backgroundColor: "#b88b72", backgroundImage: `url(${pageBackground})`}} >
    <div className="container text-center">
      <div className="row">
        <div className="col-md-8 mx-auto">
          <h1>{pageTitle}</h1>
          <h3>{pageSubTitle}</h3>
        </div>
      </div>
    </div>
  </header>

)

export default InsideHeader
